.about {
  margin: auto;
  padding: 4rem;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-color: #ecebe6;
}

.about .container:nth-child(1) {
  background-image: url("./images/Tuna-about-bg-1.jpg");
  margin-top: 100px;
}
.about .container:nth-child(2) {
  background-image: url("./images/Tuna-about-bg-2.jpg");
}
.about .container:nth-child(3) {
  background-image: url("./images/Tuna-about-bg-3.jpg");
}

.about .container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: rgb(94, 94, 94);
  background-blend-mode: multiply;

  padding: 10px;
  margin: 20px;
  width: 80%;
  height: auto;

  border-radius: 8px;

  color: white;

  box-shadow: 5px 5px 25px 0 rgba(0, 0, 0, 0.3),
    5px 5px 25px 0 rgba(0, 0, 0, 0.2), 5px 5px 25px 0 rgba(0, 0, 0, 0.1);
}

.about .container .title {
  margin: 30px 40px;
}

.about .container .title h4 {
  font-size: 30px;
  margin-bottom: 10px;
}

.about .container .more {
  text-decoration: none;
  text-align: center;
  margin-right: 60px;
}

.about .container .more a {
  display: inline-block;
  color: rgb(0, 0, 0);
  font-weight: 600;
  box-sizing: border-box;
  background-color: #f3f3f3;
  width: auto;
  height: auto;
  padding: 10px 20px;
  margin: 10px 0 10px 0;
  border-radius: 4px;

  transition: all 0.1s ease-in-out;
}

.about .container .more a:hover {
  background-color: #dfdfdf;
}

@media screen and (max-width: 940px) {
  .about .container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .about .container .title {
    margin: 20px;
    text-align: center;
  }

  .about .container .more {
    margin-right: 0px;
  }
}

.container {
  height: 82vh;
  width: 100%;
  background-color: #04080c;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.container p:first-child {
  text-align: center;
  color: #fff;
  text-transform: capitalize;
  font-size: 40px;
  margin: 3.5% 0 1% 0;
  padding: 1rem;
}

.sample-image {
  text-align: center;
  color: rgb(188, 188, 188);
  text-transform: capitalize;
  font-size: 20px;
  margin-top: 5px;
  padding-bottom: 2px;
  border-bottom: 2px solid #04080c;

  position: relative;
  left: 230px;

  transition: all 0.1s ease-in-out;
}

.container svg {
  vertical-align: middle;
  height: 25px;
  width: auto;
}

.sample-image:hover {
  border-bottom: 2px solid rgb(188, 188, 188);
}

.display-zone {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 420px;
  padding: 0 20px;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.horizontal-scroll {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 85%;
  width: 100%;
  position: relative;
  overflow-x: auto;
  overflow-y: hidden;
  touch-action: pan-x;
}

.horizontal-scroll.active {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

.horizontal-scroll::-webkit-scrollbar {
  height: 7px;
}

.horizontal-scroll::-webkit-scrollbar-track {
  background-color: #6a6060;
  border-radius: 100vw;
  margin-inline: 300px;
}

.horizontal-scroll::-webkit-scrollbar-thumb {
  background-color: #988a8a;
  border-radius: 100vw;
}

.horizontal-scroll::-webkit-scrollbar-thumb:hover {
  background-color: #cab9b9;
}

.image-box {
  display: flex;
  flex: 0 0 300px;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: 93%;
  margin: 1%;

  border-radius: 5px;
  border-color: #eeeeee;
  border-style: solid;
  background-color: #707375;

  position: relative;
  top: 0px;
  left: 20%;
  cursor: pointer;

  transition: all 0.2s ease-in-out;
  text-align: center;
}

.image-box.active {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

.image-box:hover {
  top: -10px;
  box-shadow: 5px 5px 10px 0 rgba(255, 255, 255, 0.1);
}

.image-box img {
  inline-size: 70%;
  aspect-ratio: 4 / 3;

  margin-bottom: 5px;
}

.image-box p:nth-child(2) {
  font-size: 15px;
  margin: 2px;
}

.image-box p:nth-child(3) {
  font-size: 22px;
  font-weight: 500;
  margin-top: 5px;
}

.image-box p:nth-child(4) {
  font-size: 20px;
}

.dropzone-box {
  display: flex;
  flex: 0 0 300px;
  justify-content: center;
  align-items: center;

  height: 100%;
  margin: 0.5%;
  /* margin-right: 500px; */

  background-color: #ffffff00;

  position: relative;
  left: 20%;
  cursor: pointer;
}

.button-list {
  width: 700px;
  margin: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.bt {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #3a4f7a;
  background-color: #eeeeee;
  width: auto;
  height: 50px;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: 700;
  border-radius: 5px;
  cursor: pointer;
  margin-inline: 2px;
}

.CSV {
  color: #3a4f7a;
  background-color: #eeeeee;
  width: auto;
  height: 47px;
  padding: 10px 20px;
  border-radius: 2px;
  cursor: pointer;
  margin-inline: 2px;
  font-size: 18px;
  font-weight: 620;
  line-height: 23px;
}

.add-files {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #3a4f7a;
  background-color: #eeeeee;
  width: auto;
  height: 46px;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: 700;
  border-radius: 2.5px;
  cursor: pointer;
  margin-right: auto;
}

.add-files input {
  display: none;
}

.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #fdd;
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 940px) and (min-width: 480px) {
  .button-list {
    width: 450px;
  }

  .bt {
    font-size: 16px;
    height: 35px;
    padding: 20px 15px;
  }

  .CSV {
    height: 40px;
    padding: 8px 10px;
    font-size: 16px;
    font-weight: 650;
    line-height: 21px;
    border-radius: 2.5px;
  }

  .add-files {
    font-size: 16px;
    height: 30px;
    padding: 20px 15px;
  }

  .container a {
    left: 22%;
  }
}

@media screen and (max-width: 480px) {
  .button-list {
    width: 300px;
  }

  .bt {
    font-size: 14px;
    height: 30px;
    padding: 10px 10px;
  }

  .CSV {
    height: 26.5px;
    padding: 10px 10px;
    font-size: 14px;
    font-weight: 670;
    line-height: 5px;
    border-radius: 2.2px;
  }

  .add-files {
    font-size: 14px;
    height: 26px;
    padding: 10px 10px;
  }

  .container a {
    left: 12%;
  }
}

.dropZone {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 50%;
  aspect-ratio: 1.61803;

  margin: 5px;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 0 3px #eeeeee;

  background-color: #f2e9e480;
  color: #bdbdbd;
  outline: none;
  transition: border 0.3s ease-in-out;
  cursor: pointer;

  text-align: center;
}

.dropZone.isActive {
  box-shadow: 0 0 0 3px #06b6d4;
}

.dropZone.isAccept {
  box-shadow: 0 0 0 3px #00ff2a;
}

.dropZone.isReject {
  box-shadow: 0 0 0 3px #ff0037;
}

.dropZone.notEmpty {
  width: 90%;
  height: 90%;

  border-radius: 5px;
  border-color: #eeeeee;
  border-style: solid;
  background-color: #413b3b;
  border-width: 0.2px;
}

@media screen and (max-width: 940px) {
  .dropZone {
    width: 70%;
    aspect-ratio: 1.61803;
  }
}

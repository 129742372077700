.PBF {
  background-color: #04080c;
  height: 1000px;
  width: 100%;
}

.PBF img {
  height: 300px;
}

.info {
  background-color: #fcbf49;
  position: relative;
  width: 70%;
  height: 180px;
  margin-bottom: -70px;
  margin-inline: auto;
  z-index: 5;

  border-radius: 4px;
  box-shadow: 5px 5px 25px 0 rgba(0, 0, 0, 0.3),
    5px 5px 25px 0 rgba(0, 0, 0, 0.2), 5px 5px 25px 0 rgba(0, 0, 0, 0.1);

  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.data {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
}

.data p:nth-child(1) {
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 10px;
}

.data p:nth-child(2) {
  font-size: 45px;
  font-weight: 500;
}

.data small {
  font-size: 20px;
}

.container {
  height: 100%;
  background-color: #fff;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  padding-top: 100px;

  position: relative;
}

.card {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;

  width: 600px;
  height: 700px;

  border: rgba(0, 0, 0, 0);
  border-radius: 10px;

  transition: all 0.2s ease-in-out;
}

.card:nth-child(1) {
  background-color: #d7d7d7;
  top: -25px;
  left: 15px;
  z-index: 3;
}
.card:nth-child(2) {
  background-color: #e9e9e9;
  top: 25px;
  left: -15px;
}

.card img {
  max-width: 600px;
  max-height: 280px;
  width: auto;
  height: auto;
  margin: 40px 0 30px 0;
}

.card h3 {
  font-size: 36px;
  margin-bottom: 20px;
}

.card p {
  font-size: 20px;
  font-weight: 300px;
  padding: 0 35px;
  text-align: center;
}

.card:nth-child(1):hover {
  top: -35px;
  box-shadow: 5px 5px 25px 0 rgba(0, 0, 0, 0.3),
    5px 5px 25px 0 rgba(0, 0, 0, 0.2), 5px 5px 25px 0 rgba(0, 0, 0, 0.1);
}

.card:nth-child(2):hover {
  top: 15px;
  box-shadow: 5px 5px 25px 0 rgba(0, 0, 0, 0.3),
    5px 5px 25px 0 rgba(0, 0, 0, 0.2), 5px 5px 25px 0 rgba(0, 0, 0, 0.1);
  z-index: 5;
}

@media screen and (max-width: 940px) {
  .info {
    height: 180px;
    flex-wrap: wrap;
  }

  .info::before {
    content: "";
    width: 100%;
    order: 1;
  }

  .data:nth-child(n + 3) {
    order: 1;
  }

  .data p:nth-child(1) {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 10px;
  }

  .data p:nth-child(2) {
    font-size: 32px;
    font-weight: 500;
  }

  .data small {
    font-size: 14px;
  }

  .container {
    flex-direction: column;
    gap: 20px;
  }

  .card {
    width: 90%;
    height: auto;

    transition: None;
  }

  .card:nth-child(1) {
    background-color: #e9e9e9;
    top: 0;
    left: 0;
    z-index: 0;
  }
  .card:nth-child(2) {
    top: 0;
    left: 0;
  }

  .card img {
    max-width: 250px;
    max-height: 120px;
    width: auto;
    height: auto;
    margin: 5px 0 0 0;
  }

  .card h3 {
    font-size: 20px;
    margin-bottom: 5px;
  }

  .card p {
    font-size: 16px;
    font-weight: 300px;
    padding: 0 20px 20px 20px;
    text-align: center;
  }

  .card:nth-child(1):hover {
    top: 0px;
    box-shadow: none;
  }

  .card:nth-child(2):hover {
    top: 0px;
    box-shadow: none;
  }
}

@media screen and (max-width: 1280px) and (min-width: 940px) {
  .card {
    width: 450px;
    height: 700px;
  }

  .card img {
    max-width: 400px;
    max-height: 200px;
    width: auto;
    height: auto;
    margin: 40px 0 30px 0;
  }
}

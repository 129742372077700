.footer {
  width: 100%;
  background-color: #000;
  color: #eee;
  padding: 3rem 1rem;
}

.footer .logo {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.logo img {
  width: 60px;
  margin-inline: 10px;
}

.logo p {
  font-size: 30px;
  font-weight: 600;
}

.footer .container {
  max-width: 1240px;
  margin: auto;
  text-align: center;
}

.footer ul {
  display: flex;
  justify-content: center;
}

.footer li {
  padding: 1rem;
  cursor: pointer;
}

.footer li a {
  color: #fff;
}

.footer li a:hover {
  padding-bottom: 12px;
  border-bottom: 3px solid var(--secondary-color);
}

.bottom {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-block+: 10px;
}

.bottom a {
  font-size: 5px;
}

.container p {
  font-size: 15px;
  color: rgb(136, 136, 136);
}

.icon {
  margin: 15px 10px;
}
.icon svg {
  height: 30px;
  width: auto;
  margin-bottom: 5px;
  fill: #fff;
}

.icon p {
  color: #fff;
  font-size: 15px;
}

.line {
  margin-bottom: 1rem;
}

.topView {
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.3), #04080c);
  color: #fff;
  height: 100vh;
  width: 100%;
  position: relative;
}

.topView:before {
  content: "";
  background: url("./images/Tuna-top_background.webp") no-repeat center
    center/cover;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.topView .content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1100px;
  margin: auto;
  padding: 1rem;
  align-items: center;
  line-height: 50px;
}

.topView .content p:first-child {
  font-size: 40px;
  text-align: center;
}

.topView .content p:nth-child(2) {
  font-size: 18px;
  text-align: center;
}

.topView .button {
  padding: 10px 20px;
  font-size: 120%;
  font-weight: 600;
  text-transform: uppercase;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;

  margin: 1rem 0;
  width: 30%;
  background-color: var(--secondary-color);
  border: 1px solid var(--secondary-color);
  color: #333;
}

.topView .button:hover {
  background-color: var(--secondary-dark);
  border: 1px solid var(--secondary-dark);
}

@media screen and (max-width: 940px) {
  .topView .content p:nth-child(3) {
    font-size: 2.6rem;
  }

  .topView .content p:nth-child(4) {
    font-size: 1.6rem;
  }
}
